import React from 'react'
import "./unauthorized.scss"
const Unauthorized = () => {
  return (
    <div className="unauthorized">
        <h2>Opps! Something went wrong</h2>
        <p>The page you try to enter is forbidden</p>
    </div>
  )
}

export default Unauthorized