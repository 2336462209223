import React from 'react'
import AdminUsers from '../../components/admins/users/admin-users'
import AdminTemplate from '../../templates/admin-template'

const AdminUsersPage = () => {
  return (
    <AdminTemplate><AdminUsers/></AdminTemplate>
  )
}

export default AdminUsersPage